const SVGPieChartBarLines = () => (
  <svg
    width="110"
    height="176"
    viewBox="0 0 110 176"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic illustration of a pie chart with three bar lines</title>
    <path
      d="M16.4077 136.934C19.2753 143.596 23.8387 149.39 29.6431 153.738C35.4475 158.087 42.2903 160.839 49.4895 161.719C56.6886 162.599 63.9927 161.577 70.6739 158.755C77.3551 155.933 83.18 151.409 87.5681 145.634C91.9562 139.86 94.7543 133.036 95.6835 125.843C96.6127 118.65 95.6405 111.339 92.8639 104.638C90.0873 97.9383 85.6032 92.0827 79.8585 87.6554C74.1138 83.2281 67.3091 80.3837 60.1225 79.4055L54.5259 120.526L16.4077 136.934Z"
      fill="#FEAD81"
    />
    <rect
      x="22.2461"
      y="111.278"
      width="6.24519"
      height="49.9615"
      transform="rotate(-180 22.2461 111.278)"
      fill="#808BF3"
    />
    <rect
      x="31.3301"
      y="111.278"
      width="6.24519"
      height="81.7552"
      transform="rotate(-180 31.3301 111.278)"
      fill="#808BF3"
    />
    <rect
      x="40.4141"
      y="111.278"
      width="6.24519"
      height="111.278"
      transform="rotate(-180 40.4141 111.278)"
      fill="#808BF3"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGPieChartBarLines;
