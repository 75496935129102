const SVGCircles = () => (
  <svg
    width="139"
    height="156"
    viewBox="0 0 139 156"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic illustration of circles of different sizes and two horizontal bars</title>
    <circle cx="76.5" cy="93.5" r="62.5" fill="#808BF3" />
    <circle cx="65.5" cy="14.5" r="14.5" fill="#D9DDFF" />
    <circle cx="65.5" cy="96.5" r="14.5" fill="#D9DDFF" />
    <circle cx="65.5" cy="55.5" r="14.5" fill="#D9DDFF" />
    <circle cx="65.5" cy="14.5" r="14.5" fill="#D9DDFF" />
    <path d="M37 82L0.99994 82.4907" stroke="#FDA06D" strokeWidth="5" />
    <path d="M37 90.5L0.99994 90.9907" stroke="#FDA06D" strokeWidth="5" />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGCircles;
