import { useMemo } from 'react';
import styled from 'styled-components';

import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { useContentfulPage } from 'hooks/useContentfulPage';

import { ImageBlock } from 'components/ImageBlock';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { BlogLink, SignupLink } from 'components/Links';

import { Container, HeroContainer, Section, SectionImage } from 'components/Container';

import { Cards } from 'components/Cards';
import { ProPricingBlock } from 'components/ProPricingBlock';
import { List } from 'components/List';

import PieChartBarLines from 'images/financial-advisers/pie-chart-bar-lines';
import HeroImage from 'images/financial-advisers/hero';
import Rectangles from 'images/financial-advisers/rectangles';
import Circles from 'images/financial-advisers/circles';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const StyledH3 = styled.h3`
  margin-bottom: 0;
`;

const ClearlySeeImpactList = [
  { id: 'access-investments', content: 'Access investment decisions against market events' },
  { id: 'performance-volatility', content: 'Put performance volatility in context' },
  { id: 'track-performance', content: 'Track long-term performance of a dividend portfolio' },
  { id: 'customise-report', content: 'Customise the report for a more a tailored client strategy' },
  { id: 'share-insight', content: 'Easily share portfolio insights with your clients' },
];

const StyledIframe = styled.iframe`
  /* same as image max-width so that they line up */
  max-width: 486px;
  border: none;
`;

export const FinancialAdvisers: FC<LocalizedPageProps> = () => {
  const { cards } = useContentfulPage();

  const financialAdvisersFeatures = useMemo(
    () => cards?.filter(card => card.cardIdentifier.startsWith('change-the-way-you-work-')),
    [cards]
  );
  const proFinancialAdvisers = useMemo(
    () => cards?.filter(card => card.cardIdentifier.startsWith('pro-financial-advisers-card-')),
    [cards]
  );

  return (
    <Layout>
      <Seo />

      <HeroContainer background="navyBlue" skipColoration>
        <Section>
          <h1>Sharesight Pro for financial advisers</h1>
          <p>
            A flexible and cost effective portfolio performance solution. Automate your practice
            with a 30-day free trial. No credit card needed.
          </p>

          <br />
          <br />
          <SignupLink asButton containerBackground="negative" appearance="primary" />
        </Section>

        <SectionImage image={<HeroImage />} boxShadow={false} />
      </HeroContainer>
      <Cards
        header="Cut admin and boost productivity"
        subHeader="Sharesight Pro combines all the information financial advisers need to manage and report on client investment portfolios. Powerful stock market data aggregation tools save you hours of manual administration, while comprehensive performance reports provide the insights you need to deepen client relationships."
        cards={proFinancialAdvisers}
      />
      <Container>
        <Section>
          <h2>Clearly see the impact of time on portfolio performance</h2>
          <p>
            The <BlogLink to="multi-period-report">Multi-Period Report</BlogLink> joins the recently
            launched{' '}
            <BlogLink to="value-your-investments-in-any-currency-with-sharesight">
              Multi-Currency Valuation Report
            </BlogLink>{' '}
            as part of Sharesight’s premier reporting suite. This powerful report allows advisers to
            analyse and compare portfolio performance across multiple slices of time to unlock the
            hidden drivers behind investment returns.
          </p>
          <List items={ClearlySeeImpactList} />
          <br />
          <BlogLink asButton to="multi-period-report" appearance="primary">
            Explore report
          </BlogLink>
        </Section>
        <SectionImage
          boxShadow={false}
          image={useGatsbyImage({
            name: 'financial-advisers/multi-period-report',
            alt: 'Multi period report',
          })}
          borderRadius={false}
        />
      </Container>
      <Container>
        <Section>
          <h2>Listen for yourself</h2>
          <p>
            Sharesight’s Chris Stojcevski explains why financial advisers are moving away from
            platforms and how Sharesight Pro can help in reducing client costs.
          </p>
        </Section>
        <Section center>
          <SectionImage
            image={useGatsbyImage({
              name: 'financial-advisers/chris',
              alt: 'Chris IFA Podcast',
            })}
            borderRadius={false}
            boxShadow={false}
          />
          <StyledIframe
            title="Listen to Chris's IFA Podcast"
            src="//html5-player.libsyn.com/embed/episode/id/19729019/height/90/theme/custom/thumbnail/yes/direction/backward/render-playlist/no/custom-color/10100f/"
            height="90"
            width="100%"
            scrolling="no"
            allowFullScreen
          />
        </Section>
      </Container>
      <Cards
        header="Powerful Pro features that change the way you work"
        subHeader="Keeping track of your client’s share portfolios used to be a hassle. Sharesight Pro makes it simple. Want automatic trade recording with comprehensive performance reporting, wrapped up in an easy-to-use, fully online system? That’s Sharesight Pro in a nutshell."
        cards={financialAdvisersFeatures}
      />
      <Container>
        <h2>Financial advisers love Sharesight Pro…</h2>
      </Container>
      <Container background="white" skipColoration>
        <SectionImage image={<Circles />} boxShadow={false} borderRadius={false} />
        <Section>
          <StyledH3>Peter Naude</StyledH3>
          <p>Sharesight Pro customer</p>
          <p>
            “Sharesight has allowed us to assist our clients to manage their long-term investment
            strategies.”
          </p>
        </Section>
      </Container>
      <Container background="white" skipColoration>
        <SectionImage image={<Rectangles />} boxShadow={false} borderRadius={false} />
        <Section>
          <StyledH3>Peter Small</StyledH3>
          <p>Sharesight Pro customer</p>
          <p>
            “Sharesight allows my clients to see what’s going on in their portfolios and I can see
            what is going on in each investment.”
          </p>
        </Section>
      </Container>
      <Container background="white" skipColoration>
        <SectionImage image={<PieChartBarLines />} boxShadow={false} borderRadius={false} />
        <Section>
          <StyledH3>Martin Dowse</StyledH3>
          <p>Sharesight Pro customer</p>
          <p>
            “We recommend Sharesight as there is nothing else in the space that comes close. It has
            a great look and feel and just seems to work.”
          </p>
        </Section>
      </Container>
      <ProPricingBlock />
      <Container>
        <Section>
          <h2>Sharesight Pro makes managing client portfolios a breeze</h2>

          <p>
            <strong>Easy sign-up</strong>
            <br />
            No billing details or credit card required
          </p>
          <br />
          <p>
            <strong>No hidden fees or contracts</strong>
            <br />
            Upgrade, downgrade or cancel anytime
          </p>
          <br />
          <SignupLink asButton />
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'financial-advisers/laptop',
            alt: 'Laptop with Sharesight product',
          })}
          boxShadow={false}
        />
      </Container>
      <ImageBlock variation="awards" />
    </Layout>
  );
};

export default FinancialAdvisers;
