const SVGHero = () => (
  <svg
    width="474"
    height="308"
    viewBox="0 0 474 308"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic illustration of halved spheres and a radar</title>
    <path
      d="M210.065 203.481C202.701 215.594 193.043 226.15 181.642 234.546C170.241 242.942 157.32 249.013 143.617 252.414C129.914 255.814 115.697 256.477 101.779 254.364C87.8606 252.252 74.5129 247.405 62.498 240.101C50.4831 232.796 40.0362 223.178 31.7539 211.794C23.4717 200.41 17.5161 187.485 14.2273 173.754C10.9386 160.024 10.381 145.759 12.5865 131.773C14.7919 117.786 19.7172 104.353 27.0811 92.24L118.573 147.861L210.065 203.481Z"
      fill="url(#paint0_linear_2947_26012)"
    />
    <ellipse
      cx="119.005"
      cy="147.405"
      rx="50.8904"
      ry="107.116"
      transform="rotate(121.297 119.005 147.405)"
      fill="url(#paint1_linear_2947_26012)"
    />
    <path
      d="M319.844 239.917C328.102 247.221 337.706 252.841 348.108 256.454C358.51 260.068 369.506 261.605 380.468 260.978C391.43 260.351 402.144 257.572 411.998 252.8C421.851 248.027 430.652 241.355 437.897 233.164C445.142 224.973 450.689 215.424 454.223 205.061C457.756 194.699 459.206 183.726 458.49 172.769C457.773 161.812 454.905 151.086 450.048 141.203C445.191 131.321 438.441 122.475 430.183 115.17L375.013 177.544L319.844 239.917Z"
      fill="url(#paint2_linear_2947_26012)"
    />
    <ellipse
      rx="39.5781"
      ry="83.3055"
      transform="matrix(0.749038 0.662527 0.662527 -0.749038 374.59 177.305)"
      fill="url(#paint3_linear_2947_26012)"
    />
    <circle
      cx="311.621"
      cy="119.377"
      r="63.0479"
      transform="rotate(120 311.621 119.377)"
      fill="white"
    />
    <g opacity="0.6">
      <path
        d="M280.101 173.979C249.946 156.569 239.614 118.01 257.024 87.8549C274.434 57.7 312.993 47.3681 343.148 64.7781C373.303 82.1881 383.634 120.747 366.225 150.902C348.815 181.057 310.256 191.389 280.101 173.979Z"
        fill="#FC6B1A"
      />
      <path
        d="M271.987 168.407C282.689 177.059 295.928 181.972 309.683 182.396C323.439 182.819 336.955 178.731 348.169 170.753C359.383 162.776 367.68 151.349 371.791 138.216C375.903 125.082 375.605 110.964 370.942 98.0165L311.624 119.378L271.987 168.407Z"
        fill="white"
      />
      <path
        d="M313.415 56.3568C302.276 56.0403 291.253 58.6816 281.467 64.0115C271.682 69.3415 263.483 77.1698 257.708 86.6991C251.932 96.2283 248.785 107.118 248.587 118.259C248.389 129.401 251.148 140.395 256.582 150.124C262.016 159.852 269.931 167.966 279.521 173.64C289.111 179.314 300.034 182.345 311.177 182.424C322.32 182.503 333.284 179.627 342.954 174.09C352.624 168.553 360.653 160.552 366.225 150.901L311.624 119.378L313.415 56.3568Z"
        fill="#FCB48D"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <circle
          cx="311.628"
          cy="119.378"
          r="63.1048"
          transform="rotate(115.828 311.628 119.378)"
          fill="url(#paint4_radial_2947_26012)"
        />
      </g>
    </g>
    <path
      d="M288.861 158.804C267.086 146.232 259.626 118.389 272.197 96.6147C284.769 74.8402 312.612 67.3797 334.386 79.9512C356.161 92.5228 363.621 120.366 351.05 142.14C338.478 163.915 310.635 171.375 288.861 158.804Z"
      fill="#FC6B1A"
    />
    <path
      d="M283.002 154.781C290.73 161.028 300.29 164.576 310.222 164.881C320.155 165.187 329.915 162.235 338.012 156.475C346.11 150.715 352.1 142.463 355.069 132.98C358.039 123.496 357.823 113.302 354.456 103.952L311.623 119.377L283.002 154.781Z"
      fill="white"
    />
    <path
      d="M312.917 73.8703C304.874 73.6418 296.914 75.549 289.848 79.3977C282.781 83.2464 276.862 88.8991 272.691 95.7801C268.52 102.661 266.248 110.524 266.105 118.569C265.962 126.614 267.954 134.554 271.878 141.578C275.802 148.603 281.517 154.462 288.442 158.559C295.367 162.656 303.254 164.845 311.301 164.902C319.347 164.959 327.264 162.883 334.246 158.884C341.229 154.886 347.027 149.108 351.05 142.14L311.623 119.377L312.917 73.8703Z"
      fill="#FCB48D"
    />
    <g style={{ mixBlendMode: 'multiply' }}>
      <circle
        cx="311.628"
        cy="119.378"
        r="45.5672"
        transform="rotate(115.828 311.628 119.378)"
        fill="url(#paint5_radial_2947_26012)"
      />
    </g>
    <g opacity="0.4">
      <path
        d="M277.854 177.858C245.554 159.21 234.488 117.909 253.136 85.6101C271.784 53.3108 313.085 42.2442 345.384 60.8922C377.683 79.5403 388.75 120.841 370.102 153.141C351.454 185.44 310.153 196.506 277.854 177.858Z"
        fill="#FC6B1A"
      />
      <path
        d="M269.163 171.891C280.626 181.158 294.807 186.42 309.54 186.874C324.274 187.327 338.751 182.948 350.763 174.404C362.774 165.859 371.66 153.619 376.065 139.552C380.469 125.485 380.149 110.363 375.155 96.4943L311.619 119.375L269.163 171.891Z"
        fill="white"
      />
      <path
        d="M313.537 51.8721C301.606 51.5331 289.799 54.3622 279.318 60.0712C268.836 65.7802 260.055 74.1651 253.868 84.372C247.682 94.579 244.311 106.243 244.099 118.177C243.887 130.11 246.842 141.887 252.662 152.307C258.483 162.727 266.961 171.418 277.233 177.496C287.505 183.573 299.205 186.819 311.14 186.904C323.075 186.989 334.819 183.909 345.177 177.978C355.534 172.047 364.135 163.476 370.102 153.14L311.619 119.375L313.537 51.8721Z"
        fill="#FCB48D"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <circle
          cx="311.62"
          cy="119.376"
          r="67.5922"
          transform="rotate(115.828 311.62 119.376)"
          fill="url(#paint6_radial_2947_26012)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2947_26012"
        x1="151.91"
        y1="248.031"
        x2="-3.66486"
        y2="149.058"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC6B1A" />
        <stop offset="1" stopColor="#061392" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2947_26012"
        x1="144.342"
        y1="147.405"
        x2="80.7167"
        y2="134.807"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#202EAE" />
        <stop offset="1" stopColor="#0E1B8F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2947_26012"
        x1="373.298"
        y1="259.63"
        x2="466.099"
        y2="150.305"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC6B1A" />
        <stop offset="1" stopColor="#061392" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2947_26012"
        x1="40.3437"
        y1="68.0165"
        x2="8.8911"
        y2="44.8569"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#202EAE" />
        <stop offset="1" stopColor="#0E1B8F" />
      </linearGradient>
      <radialGradient
        id="paint4_radial_2947_26012"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(347.48 121.993) rotate(-171.354) scale(100.257 80.5956)"
      >
        <stop offset="0.453125" stopColor="#DCDCDC" />
        <stop offset="1" stopColor="#FC6B1A" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_2947_26012"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(337.517 121.266) rotate(-171.354) scale(72.3946 58.1971)"
      >
        <stop offset="0.453125" stopColor="#DCDCDC" />
        <stop offset="1" stopColor="#FC6B1A" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_2947_26012"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(350.021 122.177) rotate(-171.354) scale(107.387 86.3269)"
      >
        <stop offset="0.453125" stopColor="#DCDCDC" />
        <stop offset="1" stopColor="#FC6B1A" />
      </radialGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGHero;
