const SVGRectangles = () => (
  <svg
    width="130"
    height="146"
    viewBox="0 0 130 146"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic illustration of rectangles of various sizes</title>
    <rect x="22.5" width="107.499" height="145.213" rx="8.29787" fill="#FCB48D" />
    <rect
      x="88.5098"
      y="22.1277"
      width="13.8298"
      height="88.5106"
      transform="rotate(90 88.5098 22.1277)"
      fill="#808BF3"
    />
    <rect
      x="88.5098"
      y="70.532"
      width="13.8298"
      height="88.5106"
      transform="rotate(90 88.5098 70.532)"
      fill="#808BF3"
    />
    <rect
      x="88.5098"
      y="45.6384"
      width="15.2128"
      height="88.5106"
      transform="rotate(90 88.5098 45.6384)"
      fill="#808BF3"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGRectangles;
